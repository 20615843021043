import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import {postApiV1LogFormValidationErrors} from 'lib/api/backend';

const useFormErrorLogger = ({errors, previousErrors, submitCount, previousSubmitCount, name}) => {
  useEffect(() => {
    const mappedErrors = Object.entries(errors).map(([key,value]: [string, {[key:string]: string}]) => ({field: key, message: value?.message })).filter(error => error?.message) as {field: string, message:string}[]
    const reducesErrors = mappedErrors.reduce((acc, item) => {
      acc[item.field] = item.message;
      return acc
    }, {})
    if (mappedErrors?.length && !isEqual(previousErrors, errors) && submitCount !== previousSubmitCount) {
      postApiV1LogFormValidationErrors({name: name, fields: reducesErrors});
    };
  }, [errors]);
};

export default useFormErrorLogger;
