import useFormErrorLogger from 'hooks/useFormErrorLogger';
import usePrevious from 'hooks/usePrevious';
import useScrollToError from 'hooks/useScrollToError';
import { FieldValues, UseFormProps, UseFormReturn, useForm as useFormClean } from 'react-hook-form';



import flattenObject from 'utils/dataModeling/flattenObject';


export * from 'react-hook-form';

interface CustomUseFormProps<TFieldValues, TContext> extends UseFormProps<TFieldValues, TContext> {
    disableScrolling?: boolean,
    name?: string
}

export function useForm<TFieldValues extends FieldValues = FieldValues, TContext = any>(props?: CustomUseFormProps<TFieldValues, TContext>): UseFormReturn<TFieldValues, TContext> {
    const {name, ...customFormProps} = props;
    const formMethods = useFormClean({
      shouldFocusError: false,
      reValidateMode: 'onChange',
      ...customFormProps,
    });
    const previousSubmitCount = usePrevious(formMethods?.formState?.submitCount);
    const flatErrors = flattenObject(formMethods?.formState?.errors, true);
    const previousErrors1 = usePrevious(flatErrors);
    //needs to be doubled due to the fact that changes come one render cycle later
    const previousErrors = usePrevious(previousErrors1);
    useFormErrorLogger({
      errors: flatErrors,
      previousSubmitCount,
      submitCount: formMethods?.formState?.submitCount,
      previousErrors,
      name,
    });
    useScrollToError({errors: flattenObject(formMethods?.formState?.errors, true), disabled: props?.disableScrolling, submitCount: formMethods?.formState?.submitCount, previousSubmitCount: previousSubmitCount});
    return formMethods;
};