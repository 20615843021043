import useCustomController from 'hooks/useCustomController';
import {ReactNode} from 'react';
import {useFormContext} from 'react-hook-form';

import {StandardTextFieldProps} from '@mui/material';

import TextField from '../TextField';

interface FormInputTextProps extends Omit<StandardTextFieldProps, 'variant'> {
  name: string;
  label?: ReactNode;
  placeholder?: string;
  variant?: 'standard' | 'filled';
  subText?: string;
}
export const FormInputText = ({
  name,
  label,
  placeholder,
  variant,
  subText,
  ...rest
}: FormInputTextProps) => {
  const {
    formState: {errors},
    control,
  } = useFormContext();
  const {
    fieldState: {error},
    field: {value, onChange, onBlur},
  } = useCustomController({control, name, defaultValue: ''});
  return (
    <TextField
      variant={variant}
      error={Boolean(error?.message)}
      helperText={
        subText ? (
          <>
            {error?.message}
            {error?.message ? <br></br> : null}
            {subText}
          </>
        ) : (
          error?.message
        )
      }
      label={label}
      placeholder={placeholder}
      {...rest}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
    />
  );
};
