/* eslint-disable import/no-anonymous-default-export */
import dynamic from 'next/dynamic';

import {
  getApiV1GetFeedback,
  getApiV1Stations,
  getGetApiV1GetFeedbackQueryKey,
  getGetApiV1StationsQueryKey,
} from 'lib/api/backend';

const StationsMap = dynamic(() => import('../strapi-components/StationsMap'));
const ContactForm = dynamic(() => import('../form-components/ContactForm'));
const PartnerForm = dynamic(() => import('../form-components/PartnerForm'));
const NewsletterForm = dynamic(() => import('../form-components/NewsletterForm'));
const Fahrzeugliste = dynamic(() => import('../strapi-components/Fahrzeugliste'));
const FaqKategorie = dynamic(() => import('../strapi-components/FaqKategorie'));
const GroupInfoSection = dynamic(() => import('../strapi-components/GroupInfoSection'));
const References = dynamic(() => import('../strapi-components/References'));
const StationInformation = dynamic(() => import('../strapi-components/StationInformation'));
const StationInformationCity = dynamic(() => import('../strapi-components/StationInformationCity'));
const ContentSectionLarge = dynamic(() => import('../strapi-components/ContentSectionLarge'));
const NewsGrid = dynamic(() => import('../strapi-components/NewsGrid'));
const NewsList = dynamic(() => import('../strapi-components/NewsList'));
const Seperator = dynamic(() => import('../strapi-components/Seperator'));
const Contacts = dynamic(() => import('../strapi-components/Contacts'));
const BusinessCards = dynamic(() => import('../strapi-components/BusinessCards'));
const ContentSectionSplit = dynamic(() => import('../strapi-components/ContentSectionSplit'));
const ContentSectionBasic = dynamic(() => import('../strapi-components/ContentSectionBasic'));
const TeaserGrid = dynamic(() => import('../strapi-components/TeaserGrid'));
const CarTeaser = dynamic(() => import('../strapi-components/CarTeaser'));
const TeaserSlider = dynamic(() => import('../strapi-components/TeaserSlider'));
const ContactSection = dynamic(() => import('../strapi-components/ContactSection'));
const HeroHeader = dynamic(() => import('../strapi-components/HeroHeader'));
const TitleWithBreadcrumb = dynamic(() => import('../strapi-components/TitleWithBreadcrumb'));
const HeroHeaderWithBPR = dynamic(() => import('../strapi-components/HeroHeaderWithBPR'));
const BenefitsSection = dynamic(() => import('../strapi-components/BenefitsSection'));
const StarcarVideo = dynamic(() => import('./Video'));
const ContentVideoSection = dynamic(() => import('../strapi-components/ContentVideoSection'));
const SeoTags = dynamic(() => import('../strapi-components/SeoTags'));
const ImageSection = dynamic(() => import('../strapi-components/ImageSection'));
const ContentSectionFramedImage = dynamic(
  () => import('../strapi-components/ContentSectionFramedImage'),
);
const ContentSectionImageBackground = dynamic(
  () => import('../strapi-components/ContentSectionImageBackground'),
);
const AccordionSection = dynamic(() => import('../strapi-components/AccordionSection'));
const AgenturAnforderungen = dynamic(() => import('./AgenturAnforderungen'));

/*
  Configure Query Prefetching here.
  queryfn takes the props that the Component that uses "use....Query" uses
  params that you provide to the useQuery hook you need to also Specify in the params object.
  define these props in the propsKeys array in the correct order.
  the [[...slug]].tsx page then checks for each page if there is a Component that has Queries needed to be prefetched , and queries them.
*/
export const prefetchQueryMapping: {
  [key: string]: {
    queryFn: (...args: any[]) => ((string | any)[] | (() => Promise<void | any[] | any>))[];
    propsKeys: string[];
  };
} = {
  'page-components.feedback': {
    queryFn: () => {
      return [
        getGetApiV1GetFeedbackQueryKey({from: 0, to: 10}),
        () => getApiV1GetFeedback({from: 0, to: 10}),
      ];
    },
    propsKeys: [],
  },
  'typo-components.stations-map': {
    queryFn: () => {
      return [getGetApiV1StationsQueryKey(), () => getApiV1Stations()];
    },
    propsKeys: [],
  },
};
export default {
  'forms.contact-form': ContactForm,
  'forms.partner-form': PartnerForm,
  'forms.newsletter-form': NewsletterForm,
  'typo-components.fahrzeugliste': Fahrzeugliste,
  'typo-components.faq---kategorie': FaqKategorie,
  'typo-components.group-info-section': GroupInfoSection,
  'typo-components.references': References,
  'typo-components.station-information': StationInformation,
  'typo-components.city-station-info': StationInformationCity,
  'typo-components.content-section-large': ContentSectionLarge,
  'typo-components.news-grid': NewsGrid,
  'typo-components.news-list': NewsList,
  'typo-components.seperator': Seperator,
  'typo-components.contacts': Contacts,
  'typo-components.business-cards': BusinessCards,
  'typo-components.content-section-split': ContentSectionSplit,
  'typo-components.teaser-grid': TeaserGrid,
  'typo-components.car-teaser-section': CarTeaser,
  'typo-components.teaser-slider': TeaserSlider,
  'typo-components.contact-section': ContactSection,
  'typo-components.hero-header': HeroHeader,
  'typo-components.title-with-optional-breadcrumb': TitleWithBreadcrumb,
  'typo-components.hero-header-with-bpr': HeroHeaderWithBPR,
  'typo-components.content-section-framed-image': ContentSectionFramedImage,
  'typo-components.content-section-bg-image': ContentSectionImageBackground,
  'typo-components.accordion-section': AccordionSection,
  'typo-components.benefits-section': BenefitsSection,
  'typo-components.starcar-video': StarcarVideo,
  'typo-components.content-video-section': ContentVideoSection,
  'typo-components.seo-tags': SeoTags,
  'typo-components.image-section': ImageSection,
  'typo-components.content-section-basic': ContentSectionBasic,
  'typo-components.agentur-anforderungen': AgenturAnforderungen,
  'typo-components.stations-map': StationsMap,
};
