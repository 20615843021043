import { useEffect } from 'react';

const useScrollToError = ({errors, submitCount, previousSubmitCount, disabled = false}) => {
  useEffect(() => {
    const scrollToFirstError = () => {
      const errorInputs = Object.keys(errors)
        .filter((inputName) => errors[inputName])
        .map((inputName) => document.getElementsByName(inputName)[0])
        .filter(Boolean);

      if (errorInputs.length > 0) {
        const topmostErrorInput = errorInputs.reduce((a, b) => {
          return a.getBoundingClientRect().top < b.getBoundingClientRect().top ? a : b;
        });
        // console.log(topmostErrorInput, topmostErrorInput.getBoundingClientRect().top, window.scrollY,)
        const offset = 150; // adjust this value as needed to account for your sticky header
        window.scrollTo({
          top: topmostErrorInput.getBoundingClientRect().top +  window.scrollY - offset,
          behavior: 'smooth',
        });
      }
    };
    if(!disabled && submitCount !== previousSubmitCount) {
      scrollToFirstError();
    }
  }, [errors, disabled, submitCount, previousSubmitCount]);
};

export default useScrollToError;