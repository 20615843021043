
const flattenObject = (ob, prohibitErrorObjects = false) => {
    
    // The object which contains the
    // final result
    let result = {};
    if (prohibitErrorObjects && ob?.message && ob?.ref && ob?.type) {
        return ob;
    }
    // loop through the object "ob"
    for (const i in ob) {
 
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i]) && !(prohibitErrorObjects && ob[i]?.message && ob[i]?.ref && ob[i]?.type)) {
            const temp = flattenObject(ob[i], prohibitErrorObjects);
            for (const j in temp) {
 
                // Store temp in result
                result[i + '.' + j] = temp[j];
            }
        }
 
        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }
    }
    return result;
};

export default flattenObject;